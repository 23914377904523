@import "variables.scss";

.footerContainer{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    min-height: 95vh;
    width: 80%;
    margin: auto;
    margin-top: 3em;
    background-color: black;
    .reserveSection{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30%;
        color: white;
        font-size: 0.9em;
        .learnMoreButton{
            border: solid 2px white;
            border-radius: 100px;
            padding: 0.8em 1.3em;
            background-color: black;
            color: white;
            font-weight: 700;
            font-size: 1.2em;
            text-decoration: none;
            margin-top: 2em;
            transition: all 200ms ease;
            &:hover{
                cursor: pointer;
                background-color: white;
                color: black;
                transform: scale(1.1);
                text-shadow: none;
            }
        }
    }
    .linksContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .link{
            color: $color-1;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 1.5rem;
            font-weight: 900;
            margin-bottom: 0.5em;
            transition: all 150ms ease;
            &:visited{
                color: $color-1;
            }
            &:hover{
                color: white;
                transform: scale(1.02);
            }
        }
        .subLink{
            margin-left: 1em;
            font-size: 1em;
        }
    }
    .socialsContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 30%;
        a{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: $color-1;
            height: 40px;
            width: 40px;
            border-radius: 100px;
            margin-bottom: 0.5em;
            transition: all 150ms ease;
            &:hover{
                transform: scale(1.1);
            }
            &:visited{
                color: $color-1;
            }
            img{
                width: 80%;
            }
        }
    }
    .copywriteInfo{
        color: $color-1;
        width: 100%;
        p{
            text-align: center;
        }
    }
}

@media (max-width: 600px){
    .footerContainer{
        width: 90%;
        padding-bottom: 2rem;
        .reserveSection{
            width: 90%;
            margin: 2em 0;
            font-size: 0.8em;
            h2{
                text-align: center;
            }
            .learnMoreButton{
                font-size: 1.5em;
            }
        }
        .linksContainer{
            align-items: center;
            width: 100%;
            margin-bottom: 1em;
            .subLink {
                display: none;
            }
        }
        .socialsContainer{
            flex-direction: row;
            width: 90%;
            margin-bottom: 1em;
        }
        .copywriteInfo{
            width: 100%;
        }
    }
}