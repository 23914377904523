@import url('https://fonts.googleapis.com/css2?family=Fasthand&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Chela+One&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url(./images/rock-on.png), auto;
}
h1{
  font-size: 4em;
  font-weight: 600;
  font-family: 'Chela One', cursive;
}
h2{
  font-size: 2em;
  font-weight: 600;
  font-family: 'Chela One', cursive;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
  border-radius: 10em;
  border: solid 2px white;
  color: white;
  font-weight: 600;
  font-size: 0.8em;
  background-color: transparent;
  opacity: 0.5;
  transition: all 200ms ease-in-out;
}
.button:hover {
  opacity: 1;
  cursor: pointer;
}
