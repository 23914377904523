@import "variables.scss";

.instagramContainer{
    scroll-snap-align: start;
    height: 100vh;
    overflow: hidden;
    .title{
        width: 80%;
        margin: auto;
        color: $color-1;
        font-size: 3em;
        transform: translateY(30px);
    }
    .instagramFrame{
        transform: translateY(50px);
    }
}

@media (max-width: 600px){
    .instagramContainer{
        min-height: auto;
        .title{
            font-size: 2em;
        }
        .instagramFrame{
            width: 100%;
            height: 82vh;
            overflow-y: hidden;
        }
    }
}