@import "variables.scss";

.artistsContainer {
    .newFormContainer {
        width: 50%;
    }
    .newButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3em;
        height: 3em;
        border-radius: 10em;
        border: solid 2px white;
        color: white;
        font-weight: 600;
        font-size: 1.2em;
        margin-bottom: 1em;
        background-color: transparent;
        opacity: 0.5;
        transition: all 200ms ease-in-out;
        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }
    .allArtists {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 2em 0;
        .artistContainer {
            position: relative;
            padding: 1em;
            background-color: rgba($color-1, 0.15);
            border-radius: 1em;
            width: 20em;
            min-width: 20em;
            padding-bottom: 5em;
            overflow-x: scroll;
            .singleArtistHeader {
                display: flex;
                flex-direction: column;
                margin-bottom: 1em;
                .pronouns {
                    font-size: 0.8em;
                }
                .title {
                    font-size: 1.1em;
                    font-weight: 200;
                    width: 80%;
                    font-style: italic;
                }
            }
            .contactContainer {
                position: relative;
                display: flex;
                width: 100%;
                align-items: center;
                margin-bottom: 0.3em;
                color: $color-1;
                .contactIcon {
                    font-size: 1.2em;
                    margin-right: 0.5em;
                    &.instagram {
                        font-size: 1.3em;
                    }
                    &.website {
                        font-size: 1.1em;
                    }
                }
                .contactText {
                    position: absolute;
                    left: 2em;
                    font-size: 1em;
                    text-align: center;
                }
            }
            .buttonsContainer {
                position: absolute;
                bottom: 1em;
                right: 1em;
                display: flex;
                gap: 1em;
                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 3em;
                    height: 3em;
                    border-radius: 10em;
                    border: solid 2px white;
                    color: white;
                    font-weight: 600;
                    font-size: 0.8em;
                    background-color: transparent;
                    opacity: 0.5;
                    transition: all 200ms ease-in-out;
                    &:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
                }
                .deleteButton {
                    &:hover {
                        color: red;
                        border: solid 2px red;
                    }
                }
            }
        }
    }
}