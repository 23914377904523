@import "variables.scss";

.popupContainer {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
    animation: fadeColor 3s ease-in-out;
    z-index: 5;
    &.hidden {
        display: none;
    }
    .popupContentContainer {
        position: relative;
        width: 50%;
        min-height: 50vh;
        display: flex;
        justify-content: space-around;
        padding: 1rem;
        padding-bottom: 3rem;
        background-color: $color-1;
        border-radius: 20px;
        &.inAnimation {
            animation: bounceDown 3s ease-in-out;
        }
        &.outAnimation {
            animation: bounceUp 0.75s ease-in-out;
            transform: translateY(200%);
        }
        .palmTree {
            position: absolute;
            left: -13%;
            top: -7vh;
            width: 12vh;
            transform: rotate(-50deg);
            z-index: -1;
        }
        .palmTree:nth-child(even) {
            left: -2%;
            top: -14vh;
            width: 9vh;
            transform: rotate(-10deg) scaleX(-1);
        }
        .devilDrawing {
            position: absolute;
            right: -15%;
            bottom: 10%;
            width: 25%;
            transform: rotate(20deg) scaleX(-1);
            z-index: -1;
            animation: devilDrawing 4s ease-in-out;
        }
        .imageContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-width: 10em;
            .popupImage {
                display: block;
                width: 100%;
                max-width: 100%;
                height: auto;
                border-radius: 10px;
                object-fit: contain;
            }
        }
        .infoContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 1rem;
            .popupTitle {
                font-size: 3rem;
                font-weight: bold;
                text-align: center;
                padding: 1rem;
                z-index: 2;
            }
            .popupInfo {
                position: relative;
                font-size: 1.5rem;
                padding: 1rem;
                z-index: 2;
            }
            .popupButton {
                border: solid 2px black;
                color: black;
                border-radius: 10em;
                background-color: white;
                width: fit-content;
                height:  auto;
                padding: 1em;
                margin: auto;
                text-decoration: none;
                transition: all 200ms ease-in-out;
                &:hover {
                    background-color: black;
                    color: white;
                    cursor: pointer;
                }
            }
        }
        .closeButton {
            position: absolute;
            bottom: 1rem;
            left: calc(50% - 5rem);
            width: 10rem;
            border: none;
            background-color: transparent;
            color: $color-2;
            font-size: 1rem;
            cursor: pointer;
            &:hover {
                color: $color-2;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .popupContainer {
        .popupContentContainer {
            width: 60%;
            .infoContainer {
                .popupTitle {
                    font-size: 2.5rem;
                }
                .popupInfo {
                    font-size: 1rem;
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .popupContainer {
        .popupContentContainer {
            flex-direction: column;
            align-items: center;
            width: 70%;
            padding: 0.5rem;
            padding-bottom: 3rem;
            .palmTree {
                top: -13vh;
                width: 9vh;
                transform: rotate(-15deg);
            }
            .palmTree:nth-child(even) {
                left: 15%;
                top: -10vh;
                width: 7vh;
                transform: rotate(10deg) scaleX(-1);
            }
            .imageContainer {
                width: 100%;
            }
            .infoContainer {
                padding: 0;
                margin-top: 1em;
                .popupTitle {
                    font-size: 2.5rem;
                    font-weight: bold;
                    text-align: center;
                    padding: 0rem;
                    margin: 0;
                }
                .popupInfo {
                    position: relative;
                    font-size: 1rem;
                    padding: 1rem;
                }
            }
            .ticketLink {
                &:focus {
                    background-color: rgb(247, 120, 203);
                    width: 8rem;
                    box-shadow: inset 2px 2px 3px rgb(249, 207, 234);
                    color: white;
                    font-weight: 800;
                    border: none;
                }
                &:hover {
                    background-color: transparent;
                    width: 6rem;
                    box-shadow: none;
                    color: black;
                    font-weight: 400;
                    border: solid 2px black;
                }
            }
        }
    }
}

// animation for background color
@keyframes fadeColor {
    0% {
        background-color: rgba(0,0,0,0);
    }
    80% {
        background-color: rgba(0,0,0,0);
    }
    100% {
        background-color: rgba(0,0,0,0.5);
    }

}
// animation for opening popup
@keyframes bounceDown {
    0% {
        transform: translateY(-200%);
    }
    80% {
        transform: translateY(-200%);
    }
    90% {
        transform: translateY(20%);
    }
    95% {
        transform: translateY(-5%);
    }
    100% {
        transform: translateY(0);
    }
}

// animation for closing popup
@keyframes bounceUp {
    0% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-5%);
    }
    70% {
        transform: translateY(20%);
    }
    100% {
        transform: translateY(-200%);
    }
}
// animation for devilDrawing
@keyframes devilDrawing {
    0% {
        transform: translateX(-150px) rotate(0deg) scaleX(-1);
    }
    90% {
        transform: translateX(-150px) rotate(0deg) scaleX(-1);
    }
    100% {
        transform: translateX(0) rotate(20deg) scaleX(-1);
    }
}