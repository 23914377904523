@import "variables.scss";

.navigationContainer{
    .navigation{
        transform: translateY(-150%);
        position: absolute;
        inset: 0;
        background-color: black;
        z-index: 15;
    }
    .navigationButton{
        position: absolute;
        left: 2em;
        top: 2em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        z-index: 16;
        cursor: pointer;
        opacity: 0;
        animation: fadeIn 0.8s ease-in-out forwards;
        @keyframes fadeIn{
            0%{
                opacity: 0;
            }
            100%{
                opacity: 1;
            }
        }
        .line{
            width: 100%;
            height: 3px;
            background-color: $color-1;
            border-radius: 10px;
            transition: all 150ms ease;
        }
        &.delayAnimation{
            animation-delay: 1.8s;
        }
        &.showing{
            .line1{
                transform: rotate(45deg) translate(8px, 8px);
            }
            .line2{
                opacity: 0;
            }
            .line3{
                transform: rotate(-45deg) translate(8px, -8px);
            }
        }
    }
    .showingNav{
        position: absolute;
        inset: 0;
        height: 100vh;
        overflow: hidden;
        background-color: black;
        z-index: 15;
        animation: slideIn 300ms ease-in-out forwards;
        @keyframes slideIn{
            0%{
                transform: translateY(-150%);
            }
            100%{
                transform: translateY(0%);
            }
        }
    }
    .hidingNav{
        position: absolute;
        inset: 0;
        background-color: black;
        z-index: 14;
        animation: slideOut 300ms ease-in-out forwards;
        @keyframes slideOut{
            0%{
                transform: translateY(0%);
            }
            100%{
                transform: translateY(-150%);
            }
        }
    }
}

@media (max-width: 600px){
    .navigationContainer{
        .navigationButton{
            left: auto;
            right: 2em;
        }
    }
}