@import "variables";

.glasses {
    width: 50%;
    transform: translateY(-3em);
    .stroke {
        stroke: $color-2;
    }
    .fill {
        fill: $color-2;
    }

}
.devilDrawing{
    position: relative;
    width: 25%;
    transform: translateY(-3em);
    .stroke{
        stroke: $color-1;
    }
    .fill{
        fill: $color-1;
    }
}
.devilChillin, .palmTree{
    width: 50%;
    path{
        fill: $color-1;
    }
}
.topText{
    width: 40%;
    path {
        transform-origin: center;
        transition: all 150ms ease-in-out;
        &:hover {
            transform: scale(1.03);
        }
    }
}
.bottomText{
    width: 20%;
    path {
        transform-origin: center;
        transition: all 150ms ease-in-out;
        &:hover {
            transform: scale(1.05);
        }
    }
}
.topText, .bottomText{
    fill: $color-1;
    stroke: $color-1;
}
.fadeAnimation {
    opacity: 0;
    animation: fadeIn 1.6s $animationDelay ease-in-out forwards;
}
@keyframes fadeIn{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.devilEyesOpen{
    position: relative;
    width: 25%;
    transform: translateY(-3em);
    // animation: headAnimation $heroAnimationDuration ease-in-out forwards;
    .stroke{
        stroke: $color-1;
    }
    .fill{
        fill: $color-1;
    }
    #pupil-right{
        // animation: pupilRightAnimation $heroAnimationDuration ease-in-out forwards;
    }
    #pupil-left{
        transform-origin: center;
        transform-box: fill-box;
        // animation: pupilLeftAnimation $heroAnimationDuration ease-in-out forwards;
    }
    #eye-left {
        transform-origin: center;
        transform-box:fill-box;
        // animation: winkOutAnimation $heroAnimationDuration ease-in-out forwards;
    
    }
    #wink-left {
        transform-origin: center;
        transform-box:fill-box;
        // display: none;
        // animation: winkInAnimation $heroAnimationDuration ease-in-out forwards;
    
    }
    #curved-star-1, #curved-star-2 {
        transform-origin: center;
        transform-box:fill-box;
        // display: none;
    }
    #curved-star-1 {
        transform: translate(5rem, 8.8rem) scale(0.5);
        // animation: starAnimation-1 $heroAnimationDuration ease-in-out forwards;
    }
    #curved-star-2 {
        transform: translate(5.5rem, 8rem) scale(0.2);
        // animation: starAnimation-2 $heroAnimationDuration ease-in-out forwards;
    }
}
@media (max-width: 600px){
    .devilEyesOpen{
        width: 50%;
    }
}

.derpHorse {
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 50px;
    height: auto;
    transform: translateX(200px) rotate(45deg);
    transform-origin: bottom right;
    transition: all 300ms ease-in-out;
}

// begginning animations
@keyframes headAnimation {
    0%{
        transform: translate(0, 3rem) scale(8);
        opacity: 0;
    }
    20% {
        transform: translate(-10rem, 3rem) scale(8);
        opacity: 1;
    }
    30% {
        transform: translate(-10rem, 3rem) scale(8);
    }
    65% {
        transform: translate(10rem, 3rem) scale(8);
    }
    60% {
        transform: translate(10rem, 3rem) scale(8);
    }
    80%{
        transform: translate(0, 3rem) scale(8) rotate(0deg);
    }
    85% {
        transform: translate(0, 3rem) scale(8) rotate(-5deg);
    }
    90% {
        transform: translate(0, 3rem) scale(auto) rotate(0deg);
    }
    100%{
        transform: translate(0rem, -3em) scale(1) rotate(0deg);
    }
}
@keyframes pupilRightAnimation {
    0%{
        transform: translate(0em, 0);
    }
    20% {
        transform: translate(0.4em, -2px);
    }
    30% {
        transform: translate(0.4em, -2px);
    }
    50%{
        transform: translate(-0.5em, 0);
    }
    60%{
        transform: translate(-0.5em, 0);
    }
    80% {
        transform: translate(0em, 0);
    }
    100%{
        transform: translate(0em, 0);
    }

}
@keyframes pupilLeftAnimation {
    0%{
        transform: translate(0em, 0);
    }
    20% {
        transform: translate(0.4em, -2px);
    }
    30% {
        transform: translate(0.4em, -2px);
    }
    50%{
        transform: translate(-0.5em, 0);
    }
    60%{
        transform: translate(-0.5em, 0);
    }
    80% {
        transform: scaleY(1) translate(0em, 0);
    }
    85% {
        transform: scaleY(0) translate(0em, 0);
    }
    100%{
        transform: scaleY(0) translate(0em, 0);
    }

}
@keyframes winkOutAnimation {
    0%{
        transform: scaleY(1);
    }
    80% {
        transform: scaleY(1);
    }
    85% {
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(0);
    }
}

@keyframes winkInAnimation {
    0%{
        transform: scaleY(0);
    }
    82% {
        transform: scaleY(0);
    }
    90% {
        transform: scaleY(1);
    }
    100%{
        transform: scaleY(1);
    }
}
@keyframes starAnimation-1 {
    0%{
        transform: translate(5rem, 8.8rem) scale(0);
    }
    82% {
        transform: translate(5rem, 8.8rem) scale(0);
        opacity: 1;
    }
    95% {
        transform: translate(5rem, 8.8rem) scale(1);
        opacity: 0;
    }
    100%{
        transform: translate(5rem, 8.8rem) scale(1);
        opacity: 0;
    }

}
@keyframes starAnimation-2 {
    0%{
        transform: translate(5.5rem, 8rem) scale(0);
    }
    82% {
        transform: translate(5.5rem, 8rem) scale(0);
        opacity: 1;
    }
    95% {
        transform: translate(5.5rem, 8rem) scale(1);
        opacity: 0;
    }
    100%{
        transform: translate(5.5rem, 8rem) scale(1);
        opacity: 0;
    }

}