
.adminNavigation {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1em 2em;
    z-index: 10;
    .background {
        position: absolute;
        inset: 0;
        background-color: transparent;
        background-image: radial-gradient(transparent 1px, var(rgb(43, 86, 172), #ffffff) 1px);
        background-size: 4px 4px;
        backdrop-filter: blur(1px);
        mask: linear-gradient(rgb(0, 0, 0) 60%, rgba(0, 0, 0, 0) 100%);
        opacity: 1;
        z-index: -1;
    }
    .leftSide {
        display: flex;
        align-items: center;
        gap: 3em;
    }
    .rightSide {
        .tutorialLink {
            font-size: 0.8em;
            border: solid 1px white;
            padding: 1em;
            border-radius: 10em;
        }
    }
    h2 {
        color: white;
    }
    a {
        color: white;
        text-decoration: none;
    }
    .backToHomeContainer {
        display: flex;
        &:hover>.derpHorse {
            transform: translateX(0) rotate(0deg);
        }
    }
}