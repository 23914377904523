@import "variables.scss";

.heroContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: black;
    .topText, .bottomText {
        &.fadeAnimation {
            opacity: 0;
            animation: fadeIn 1.6s $animationDelay ease-in-out forwards;
        }
    }
    .devilEyesOpen {
        animation: headAnimation $heroAnimationDuration ease-in-out forwards;
        #pupil-right{
            animation: pupilRightAnimation $heroAnimationDuration ease-in-out forwards;
        }
        #pupil-left{
            transform-origin: center;
            transform-box: fill-box;
            animation: pupilLeftAnimation $heroAnimationDuration ease-in-out forwards;
        }
        #eye-left {
            transform-origin: center;
            transform-box:fill-box;
            animation: winkOutAnimation $heroAnimationDuration ease-in-out forwards;
        
        }
        #wink-left {
            transform-origin: center;
            transform-box:fill-box;
            animation: winkInAnimation $heroAnimationDuration ease-in-out forwards;
        
        }
        #curved-star-1, #curved-star-2 {
            transform-origin: center;
            transform-box:fill-box;
        }
        #curved-star-1 {
            transform: translate(5rem, 8.8rem) scale(0.5);
            animation: starAnimation-1 $heroAnimationDuration ease-in-out forwards;
        }
        #curved-star-2 {
            transform: translate(5.5rem, 8rem) scale(0.2);
            animation: starAnimation-2 $heroAnimationDuration ease-in-out forwards;
        }
    }
    .timeContainer{
        display: flex;
        flex-direction: row;
        margin-top: 2em;
        gap: 3em;
        // opacity: 0;
        // animation: fadeIn 0.8s $animationDelay ease-in-out forwards;
        &.fadeAnimation {
            opacity: 0;
            animation: fadeIn 1.6s $animationDelay ease-in-out forwards;
        }
        .time{
            color: $color-1;
            font-weight: 500;
        }
    }
    @keyframes fadeIn{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
}

@media (max-width: 600px){
    .heroContainer{
        .devilDrawing{
            width: 50%;
        }
        .topText{
            width: 80%;
        }
        .bottomText{
            width: 40%;
        }
        .timeContainer{
            flex-direction: column;
            align-items: center;
            gap: 1em;
        }
    }
}

@keyframes bounce{
    0%{
        transform: translateY(-3em);
    }
    40%{
        transform: translateY(-3.5em);
    }
    100%{
        transform: translateY(-3em);
    }
}

@keyframes shrink {
    0%{
        transform: translateY(-3em) scale(8);
        z-index: 10;
        background-color: black;
    }
    100%{
        transform: translateY(-3em) scale(1);
        z-index: 10;
        background-color: black;
    }
}


// begginning animations
@keyframes headAnimation {
    0%{
        transform: translate(0, 3rem) scale(8);
        opacity: 0;
    }
    20% {
        transform: translate(-10rem, 3rem) scale(8);
        opacity: 1;
    }
    30% {
        transform: translate(-10rem, 3rem) scale(8);
    }
    65% {
        transform: translate(10rem, 3rem) scale(8);
    }
    60% {
        transform: translate(10rem, 3rem) scale(8);
    }
    80%{
        transform: translate(0, 3rem) scale(8) rotate(0deg);
    }
    85% {
        transform: translate(0, 3rem) scale(8) rotate(-5deg);
    }
    90% {
        transform: translate(0, 3rem) scale(auto) rotate(0deg);
    }
    100%{
        transform: translate(0rem, -3em) scale(1) rotate(0deg);
    }
}
@keyframes pupilRightAnimation {
    0%{
        transform: translate(0em, 0);
    }
    20% {
        transform: translate(0.4em, -2px);
    }
    30% {
        transform: translate(0.4em, -2px);
    }
    50%{
        transform: translate(-0.5em, 0);
    }
    60%{
        transform: translate(-0.5em, 0);
    }
    80% {
        transform: translate(0em, 0);
    }
    100%{
        transform: translate(0em, 0);
    }

}
@keyframes pupilLeftAnimation {
    0%{
        transform: translate(0em, 0);
    }
    20% {
        transform: translate(0.4em, -2px);
    }
    30% {
        transform: translate(0.4em, -2px);
    }
    50%{
        transform: translate(-0.5em, 0);
    }
    60%{
        transform: translate(-0.5em, 0);
    }
    80% {
        transform: scaleY(1) translate(0em, 0);
    }
    85% {
        transform: scaleY(0) translate(0em, 0);
    }
    100%{
        transform: scaleY(0) translate(0em, 0);
    }

}
@keyframes winkOutAnimation {
    0%{
        transform: scaleY(1);
    }
    80% {
        transform: scaleY(1);
    }
    85% {
        transform: scaleY(0);
    }
    100%{
        transform: scaleY(0);
    }
}

@keyframes winkInAnimation {
    0%{
        transform: scaleY(0);
    }
    82% {
        transform: scaleY(0);
    }
    90% {
        transform: scaleY(1);
    }
    100%{
        transform: scaleY(1);
    }
}
@keyframes starAnimation-1 {
    0%{
        transform: translate(5rem, 8.8rem) scale(0);
    }
    82% {
        transform: translate(5rem, 8.8rem) scale(0);
        opacity: 1;
    }
    95% {
        transform: translate(5rem, 8.8rem) scale(1);
        opacity: 0;
    }
    100%{
        transform: translate(5rem, 8.8rem) scale(1);
        opacity: 0;
    }

}
@keyframes starAnimation-2 {
    0%{
        transform: translate(5.5rem, 8rem) scale(0);
    }
    82% {
        transform: translate(5.5rem, 8rem) scale(0);
        opacity: 1;
    }
    95% {
        transform: translate(5.5rem, 8rem) scale(1);
        opacity: 0;
    }
    100%{
        transform: translate(5.5rem, 8rem) scale(1);
        opacity: 0;
    }

}