@import "variables";
.menuContainer{
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
    padding-top: 3em;
    .titleContainer {
        display: flex;
        align-items: flex-end;
        margin-bottom: 3cqb;
        .menuTitle{
            color: $color-1;
            margin-right: 0.2em;
        }
        .menuTagLine {
            color: $color-1;
            margin-bottom: 0.2em;
        }
    }
    .drinkTitle{
        font-weight: 700;
        color: $color-1;
    }
    .tagLine{
        color: $color-1;
        font-size: 1.1em;
        font-weight: 400;
        margin-bottom: 1em;
    }
    .drinksContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1em 5em;
        width: 100%;
        margin-bottom: 3em;
    }
}

@media only screen and (max-width: 600px){
    .menuContainer{
        width: 80%;
        .titleContainer {
            align-items: flex-start;
            flex-direction: column;
        }
        .drinksContainer{
            grid-template-columns: 1fr;
        }
    }
}