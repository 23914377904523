
.popupControlContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .contentBox {
        width: 60%;
        height: 90vh;
        border-radius: 1em;
        padding: 1em;
        background-color: rgba(white, 0.2);
        color: white;
    }
    .controls {
        display: flex;
        flex-direction: column;
        gap: 1em;
        width: 35%;
        .infoContainer {
            position: relative;
            .popupHiddenText {
                position: absolute;
                inset: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: black;
                background-color: rgba(white, 0.4);
                border-radius: 1em;
                backdrop-filter: blur(5px);
                z-index: 2;
            }
        }
        .textLabel {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 0.2em 0.5em;
            width: 70%;
            min-width: 10em;
            margin-top: 0.5em;
            .text {
                // width: 50%;
                margin-left: 0.6em;
                font-weight: 600;
            }
            input[type=text], textarea {
                border-radius: 10em;
                border: none;
                padding: 0.5em;
                min-width: 20em;
                opacity: 0.6;
                width: 90%;
                &:focus {
                    opacity: 1;
                }
            }
            textarea {
                border-radius: 0.5em;
                height: 5em;
            }
            .newImageButton {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1em;
                border-radius: 0.5em;
                background-color: rgba(white, 0.2);
                width: 50%;
                transition: all 200ms ease-in-out;
                &.fileUploaded {
                    background-color: rgba(52, 208, 57, 0.5);
                    outline: solid 2px rgba(white, 0.6);
                    width: 60%;
                }
                .imageIcon {
                    transform: scale(1);
                    transition: all 200ms ease-in-out;
                }
                &:hover {
                    background-color: rgba(white, 0.3);
                    cursor: pointer;
                    outline: solid 2px rgba(white, 0.5);
                    .imageIcon {
                        transform: scale(1.4);
                    }
                }
            }
            .showingLabel {
                display: flex;
                align-items: center;
                justify-content: center;
                input[type=checkbox] {
                    display: none;
                }
                svg {
                    font-size: 1.3em;
                }
            }
        }
        .saveButton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            padding: 0.8em;
            border-radius: 10em;
            font-size: 1.2em;
            font-weight: 600;
            background-color: rgba(52, 208, 57, 0.5);
            color: white;
            border: solid 2px rgba(white, 0.3);
            text-align: center;
            transition: all 200ms ease-in-out;
            animation: bounceIn 3700ms ease-in-out infinite;
            &:hover {
                background-color: rgba(52, 208, 57, 0.8);
                border: solid 2px rgba(white, 0.5);
                animation: none;
                cursor: pointer;
            }
            &.success {
                animation: successAnimation 1200ms ease-in-out;
            }
            @keyframes successAnimation {
                0% {
                    width: 50%;
                }
                60% {
                    width: 3em;
                    transform: scale(1);
                }
                90% {
                    width: 3em;
                    transform: scale(1.4);
                    opacity: 1;
                }
                100% {
                    width: 3em;
                    transform: scale(0.1);
                    opacity: 0;
                }
            }
            @keyframes bounceIn {
                0% {
                    transform: scale(1);
                }
                5% {
                    transform: scale(1.1);
                }
                10% {
                    transform: scale(1);
                }
                15% {
                    transform: scale(1.1);
                }
                20% {
                    transform: scale(1);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
        .resetButton {
            background-color: transparent;
            border: none;
            color: white;
            width: 50%;
            opacity: 0.6;
            transition: all 200ms ease-in-out;
            &:hover {
                opacity: 1;
                cursor: pointer;
            }
        }
    }
    .preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        .previewContainer {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1em;
            min-height: 50%;
            width: 80%;
            padding: 1em;
            background-color: white;
            color: black;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
            border-radius: 1em;
            margin-top: 2em;
            padding-bottom: 3em;
            overflow: hidden;
            &.hidden {
                opacity: 0.3;
            }
            &.mobileView {
                flex-direction: column;
                gap: 1em;
                width: 40%;
                padding: 0.5em;
                padding-bottom: 3em;
                .image {
                    width: 100%;
                }
                .textContainer {
                    min-width: 80%;
                    max-width: 80%;
                }
            }
            .image{
                width: 40%;
                border-radius: 0.5em;
            } 
            .textContainer {
                display: flex;
                flex-direction: column;
                gap: 1.5em;
                min-width: 40%;
                max-width: 80%;
                .title {
                    width: 100%;
                    text-align: center;
                    text-wrap: wrap;
                }
                .description {
                    font-size: 1.1em;
                }
                .exampleButton {
                    border: solid 2px black;
                    color: black;
                    border-radius: 10em;
                    background-color: white;
                    width: fit-content;
                    height:  auto;
                    padding: 1em;
                    margin: auto;
                    text-decoration: none;
                    transition: all 200ms ease-in-out;
                    &:hover {
                        background-color: black;
                        color: white;
                        cursor: pointer;
                    }
                }
            } 
            .continueButton {
                position: absolute;
                bottom: 1.5em;
                width: 100%;
                text-align: center;
                font-size: 0.8em;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .popupControlContainer {
        flex-direction: column;
        gap: 1em;
        .contentBox {
            width: 90%;
            margin: auto;
        }
        .controls {
            width: 90%;
            .textLabel {
                width: 100%;
            }
            .saveButton {
                width: 100%;
            }
            .resetButton {
                width: 100%;
            }
        }
        .preview {
            .previewContainer {
                width: 90%;
                &.mobileView {
                    flex-direction: column;
                    gap: 1em;
                    width: 80%;
                    padding: 0.5em;
                    padding-bottom: 3em;
                    .image {
                        width: 100%;
                    }
                    .textContainer {
                        min-width: 80%;
                        max-width: 80%;
                    }
                }
                .image {
                    width: 50%;
                }
                .textContainer {
                    min-width: 50%;
                    max-width: 50%;
                }
            }
        }
    }
};