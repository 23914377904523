.adminNavContainer {
    position: sticky;
    top: 4em;
    height: 70vh;
    display: flex;
    flex-direction: column;
    width: 20%;
    padding: 1em;
    border-radius: 1em;
    // border: solid 1px rgba(255, 255, 255, 0.3);
    // background-color: rgba(255, 255, 255, 0.15);
    .menuNavContainer {
        display: flex;
        flex-direction: column;
        margin-top: 1em;
        .adminSubContainer {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 1em;
            padding: 0 1em;
            margin-left: 1em;
            margin-top: 1em;
            border-radius: 5px;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 3px;
                height: 100%;
                border-radius: 1em;
                background-color:rgba(255, 255, 255, 0.3);
            }
        }
        .adminNavElement {
            color: white;
            text-decoration: none;
            transition: all 200ms ease-in-out;
            &.parentNav {
                font-size: 1.2em;
                font-weight: bold;
            }
            &.childNav {
                color: rgba(255, 255, 255, 0.8);
            }
            &.active {
                color: white;
                text-decoration: underline;
            }
            &:hover {
                color: white;
                text-decoration: underline;
            }
        }
    }
}