
.artistLinkContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: auto;
    padding: 5em 2em;
    border-top: solid 1px rgba(255, 255, 255, 0.5);
    border-bottom: solid 1px rgba(255, 255, 255, 0.5);
    color: white;
    overflow: hidden;
    .artistLink {
        text-align: center;
        text-decoration: none;
        color: white;
        padding: 1em;
        width: fit-content;
        border: solid 1px white;
        border-radius: 10em;
        margin-top: 2em;
        z-index: 1;
        transition: all 0.3s;
        &:hover {
            background-color: white;
            color: black;
        }
    }
    .backgroundImageContainer {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        opacity: 0.2;
        .devilDrawing {
            position: absolute;
            right: 0;
            top: -2em;
            width: 32em;
        }
        .paintBrushIcon {
            position: absolute;
            bottom: 0;
            right: 23em;
            width: 13em;
            height: auto;
            transform: rotate(160deg);
        }
    }
}
@media (max-width: 600px) {
    .artistLinkContainer {
        .header {
            font-size: 3em;
        }
    }
}