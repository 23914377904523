
.App{
    background-color: black;
    height: 100vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    .lightsImage{
        position: absolute;
        inset: 0;
    }
}
