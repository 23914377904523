.userContainer {
    position: absolute;
    top: 1.5em;
    right: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    height: 3em;
    width: 3em;
    border: solid 2px white;
    color: white;
    overflow: hidden;
    z-index: 100;
    background-color: rgba(0,0,0,0.7);
    backdrop-filter: blur(5px);
    transition: all 100ms ease-in-out;
    &.large {
        width: 20%;
        height: auto;
        border-radius: 1em;
        align-items: center;
        justify-content: flex-start;
        .hornsIcons {
            width: 30%;
        }
    }
    .hornsIcons {
        font-size: 2em;
        transform: translateY(5px);
    }
    .infoContainer {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        padding: 0.5em;
        color: white;
        border-radius: 10px;
        .userEmail {
            font-weight: 600;
        }
        .logout {
            cursor: pointer;
            width: fit-content;
            padding: 1em;
            opacity: 1;
            &:hover {
                background-color: white;
                color: black;
            }
        }
    }
}