
.carouselContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: black;
    overflow: hidden;
    .image {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
    .backgroundActiveImage {
        position: absolute;
        top: 0;
        left: 0;
        height: auto;
        width: 100%;
        height: 100%;
        filter: blur(8px) brightness(0.3);
        z-index: 0;
    }
    .activeImageContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 100%;
        padding: 2rem;
        z-index: 2;
        .activeImage {
            max-width: calc(100% - 4rem);
            border-radius: 15px;
            box-shadow: 0px 0px 5px rgba(0,0,0,0.7);
            z-index: 1;
            transition: opacity 300ms ease-in-out;
        }
    }
    .arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 20px;
        min-width: 20px;
        height: 30px;
        width: 30px;
        border-radius: 5px;
        color: white;
        z-index: 2;
        border-top-right-radius: 2px;
        transition: all 300ms ease-in-out;
        &:hover {
            opacity: 1;
            cursor: pointer;
            scale: 1.1;
        }
        &.prev {
            margin-right: 2rem;
        }
        &.next {
            margin-left: 2rem;
            transform: rotate(180deg);
        }
    }
    .imagesContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 10;
        gap: 2rem;
        width: 10%;
        padding: 1rem;
        overflow-y: scroll;
        max-height: 100%;
        min-width: 8rem;
        background-color: black;
        transition: all 300ms ease-in-out;
        .inactiveImage {
            width: 100px;
            height: 150px;
            min-height: 150px;
            border-radius: 10px;
            box-shadow: 2px 2px 5px rgba(0,0,0,0.7);
            overflow: hidden;
            transition: all 300ms ease-in-out;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            
            }
            &:hover {
                cursor: pointer;
                transform: scale(1.1);
            }
        }
        .activeSmallImage {
            border: solid 2px white;
        }
    }
    .loadingContainer {
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
        background-color: black;
        z-index: 100;
        .devilEyesOpen {
            position: absolute;
            width: 250px;
            transform: translateY(-100px) rotate(5deg);
            #wink-left {
                display: none;
            }
            #curved-star-1, #curved-star-2 {
                display: none;
            }
            #pupil-left, #pupil-right {
                animation: pupils 2.5s ease-in-out infinite;
            }
        }
        .loader {
            position: relative;
            display: flex;
            align-items: center;
            height: 200px;
            width: 200px;
            background-color: black;
            .loadingDot {
                position: absolute;
                left: 0;
                height: 15px;
                width: 15px;
                background-color: white;
                border-radius: 50%;
                animation: loadingBall 2.5s ease-in-out infinite;
                &:nth-child(1) {
                    animation-delay: 0.1s;
                }
                &:nth-child(2) {
                    animation-delay: 0.2s;
                }
            }
        }
    
    }
}

@media screen and (max-width: 768px) {
    .carouselContainer {
        flex-direction: column;
        .activeImageContainer {
            width: 100%;
            padding: 0;
            .activeImage {
                max-width: calc(100%);
                width: 100%;
            }
            .arrow {
                position: absolute;
                background-color: rgba(0,0,0,0.6);
                &.prev {
                    left: 0.5rem;
                }
                &.next {
                    right: 0.5rem;
                }
            }
        }
        .imagesContainer {
            flex-direction: row;
            width: 100%;
            height: 10%;
            min-height: 8rem;
            max-width: 100%;
            padding: 1rem;
            overflow-x: scroll;
            overflow-y: hidden;
            .inactiveImage {
                width: 150px;
                height: 100px;
                min-width: 150px;
                border-radius: 10px;
                box-shadow: 2px 2px 5px rgba(0,0,0,0.7);
                overflow: hidden;
                transition: all 300ms ease-in-out;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    
}

@keyframes loadingBall {
    0% {
        left: 0;
        scale: 1;
    }
    27% {
        scale: 1.5;
    }
    35% {
        left: 100%;
        scale: 1;
    }
    50% {
        left: 100%;
        scale: 1;
    }
    70% {
        scale: 1.5;
    }
    85% {
        left: 0;
        scale: 1;
    }
    100% {
        left: 0;
    }
}
@keyframes pupils {
    0% {
        transform: translate(-10px, 0px);
    }
    40% {
        transform: translate(7px, -2px);
    }
    50% {
        transform: translate(7px, -2px);
    }
    90% {
        transform: translate(-10px, 0px);
    }
    100% {
        transform: translate(-10px, 0px);
    }
}
@keyframes grow {
    0% {
        height: 150px;
        width: 0px;
    }
    100% {
        height: 150px;
        width: 100px;
    }
}