
.mapContainer{
    position: relative;
    .map{
        width: 100%;
        height: 400px;
        filter: invert(90%) hue-rotate(180deg) grayscale(0.2);
        border-radius: 10px;
        border: none;
    }
    .maskLayer{
        position: absolute;
        inset: 0;
        background: linear-gradient(90deg, black 0%, transparent 5%, transparent 95%, black 100%), linear-gradient(0deg, black 0%, transparent 5%, transparent 95%, black 100%);
        pointer-events: none;
    }
}

@media only screen and (max-width: 600px){
    .mapContainer{
        .map{
            height: 300px;
        }
    }
}