
.onOffLabel {
    input[type=checkbox] {
        display: none;
    }
    .sliderContainer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10em;
        width: 6em;
        background-color: rgba(white, 0.2);
        cursor: pointer;
        transition: all 200ms ease-in-out;
        .activeBackground {
            position: absolute;
            left: 0;
            width: 50%;
            height: 100%;
            border-radius: 10em;
            background-color: rgba(white, 0.4);
            transition: all 200ms ease-in-out;
            &.right {
                left: 50%;
            }
        }
        .regEye, .slashEye {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.4em;
            border-radius: 10em;
            width: 50%;
        }
        &.showing {
            background-color: rgba(52, 208, 57, 0.5);
        }
        &.showingButton {
            margin-left: 1em;
        }
    }
}