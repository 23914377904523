@import 'variables.scss';
// repushing location css
.locationContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    margin: auto;
    color: $color-1;
    .locationInfo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        .locationImage{
            width: 50%;
            background-size: cover;
            background-position: center;
            svg {
                width: 100%;
            }
        }
        .locationAddress{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 2em;
            width: 60%;
        }
        .hoursContainer{
            margin-top: 1em;
            width: 80%;
            .row{
                display: flex;
                align-items: flex-start;
                justify-content: space-around;
                padding: 0.5em 0;
                border-radius: 5px;
                &:nth-child(odd){
                    background-color: rgba($color-1, 0.15);
                }
                &.activeDay{
                    position: relative;
                    border: solid 1px $color-1;
                    &:after{
                        content: "";
                        position: absolute;
                        transform: translateX(-20px);
                        left: 0;
                        top: calc(50% - 5px);
                        height: 10px;
                        width: 10px;
                        border-radius: 50px;
                        background-color: $color-1;
                    }
                }
                .day{
                    width: 30%;
                    text-align: left;
                    font-weight: 700;
                }
                .hours{
                    text-align: left;
                }
            }
        }
    }
}

@media (max-width: 600px){
    .locationContainer{
        .locationInfo{
            flex-direction: column;
            justify-content: center;
            .locationImage{
                width: 100%;
                height: 50vh;
            }
            .locationStreet {
                font-size: 3.5em;
            }
            .locationAddress{
                align-items: center;
                width: 80%;
                margin: 0;
                margin-top: 1em;
                font-size: 0.7em;
            }
            .hoursContainer{
                width: 100%;
            }
        }
    }
}