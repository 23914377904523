@import "variables";
.menuItem{
    display: flex;
    flex-direction: column;
    color: $color-1;
    margin-top: 1em;
    .menuItemName{
        font-size: 1.2rem;
        font-weight: 800;
    }
    .menuItemDescription{
        width: 100%;
        margin-top: 0.5em;
    }
    .menuItemTagLine {
        font-size: 0.9rem;
        font-weight: 300;
        font-style: italic;
        margin-left: 0.4rem;
    }
}